import axios from 'axios';
import qs from 'qs';
import { showMessage } from '../utils/notification';

export const api = axios.create({
  headers: { 'Access-Control-Allow-Origin': '*' },
  baseURL: '/api',
  /* Serializer FROM params[]='value&params[]='value2' TO params='value&params='value2'*/
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 422) {
      showMessage('error', error?.response?.statusText || `Error with status: ${error?.response?.status}`);
    } else {
      showMessage('error', error?.response?.data?.detail || `Error with status: ${error?.response?.status}`);
    }
    throw error;
  }
);
