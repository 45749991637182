import { api } from '.';
import { PAGE_DEFAULT, PAGE_LIMIT } from '../utils/const';

export const getTags = (page = PAGE_DEFAULT, limit = PAGE_LIMIT, searchCriteria = null, searchParams = null) =>
  api.get(`/tags/`, { params: { page, limit, searchCriteria, ...searchParams } });

export const createTag = (data) => api.post(`/tags/`, { ...data });

export const updateTag = (objectId, data) => api.patch(`/tags/${objectId}`, { ...data });

export const deleteTag = (objectId, data) => api.delete(`/tags/${objectId}`, { ...data });

export const deleteTags = (data) => api.patch(`/tags/`, { ...data });

export const transferTags = (groupId, data) => api.patch(`/tags/transfer/${groupId}`, { ...data });

export const getTagModerationNotification = () => api.get(`/tags/notification/moderation`);
