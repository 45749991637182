import { notification, message } from 'antd';

export const openNotificationWithIcon = (type, message, description, duration = 4.0) => {
  notification[type]({
    message,
    description,
    duration,
  });
};

export const showMessage = (type, text, duration = 4.0) => {
  switch (type) {
    case 'success': {
      message.success(text, duration);
      break;
    }
    case 'error': {
      message.error(text, duration);
      break;
    }
    case 'warning': {
      message.warning(text, duration);
      break;
    }
    default:
      message.info(text, duration);
  }
};
