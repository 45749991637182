import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import SideNav from '../components/sidebar';
import isLogged from '../utils/auth';
import history from '../utils/history';

const PlanningComponent = lazy(() => import('../components/pages/planning'));
const HomePage = lazy(() => import('../components/pages/home'));
const CharactersComponent = lazy(() => import('../components/pages/character'));
const CommentListComponent = lazy(() => import('../components/pages/comment'));
const UsersListComponent = lazy(() => import('../components/pages/user/usersManager'));
const StoriesList = lazy(() => import('../components/pages/story/StoriesList'));
const BannerListComponent = lazy(() => import('../components/pages/banner'));
const EpisodesListComponent = lazy(() => import('../components/pages/episode'));

const CreateUserComponent = lazy(() => import('../components/pages/user/createUser'));
const EditUserComponent = lazy(() => import('../components/pages/user/edit'));
const CreateStoryComponent = lazy(() => import('../components/pages/story/create'));
const EditStoryComponent = lazy(() => import('../components/pages/story/editStory'));
const EditCharacterComponent = lazy(() => import('../components/pages/character/edit'));
const EditBannerComponent = lazy(() => import('../components/pages/banner/edit'));
const EditEpisodeComponent = lazy(() => import('../components/pages/episode/edit'));
const EditTimeStapsComponent = lazy(() => import('../components/pages/edittimestaps'));
const EditCommentsComponent = lazy(() => import('../components/pages/comment/edit'));
const LoginComponent = lazy(() => import('../components/pages/login'));
const ToolsComponent = lazy(() => import('../components/pages/tools'));
const CatalogComponent = lazy(() => import('../components/pages/catalog'));
const ClonesComponent = lazy(() => import('../components/pages/clone'));
const PushComponent = lazy(() => import('../components/pages/push'));
const TagsComponent = lazy(() => import('../components/pages/tag'));
const FandomsComponent = lazy(() => import('../components/pages/fandom'));
const SubscriptionPlansComponent = lazy(() => import('../components/pages/subscription-plan'));
const CategoriesComponent = lazy(() => import('../components/pages/category'));
const SubscriptionsComponent = lazy(() => import('../components/pages/subscription'));
const EditSubscriptionComponent = lazy(() => import('../components/pages/subscription/edit'));
const BlacklistComponent = lazy(() => import('../components/pages/blacklist'));

const { Header, Sider, Content } = Layout;

const ApplicationRoutes = () => {
  const [collapse, setCollapse] = useState(false);
  const [logged, setLogged] = useState(false);
  const [role, setRole] = useState('');

  useEffect(() => {
    window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
    loginStatus();
  }, []);

  const loginStatus = () => {
    const auth = isLogged();
    setLogged(auth['logged']);
    const user = JSON.parse(auth['user']);
    setRole(user?.role);
  };

  const handleToggle = (event) => {
    event.preventDefault();
    collapse ? setCollapse(false) : setCollapse(true);
  };

  return (
    <Router>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapse}>
          <SideNav isAuthed={loginStatus} logged={logged} role={role} />
        </Sider>
        <Layout>
          <Header className="siteLayoutBackground" style={{ padding: 0, background: '#001529' }}>
            {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: handleToggle,
              style: { color: '#fff' },
            })}
            <span style={{ fontSize: 20, color: 'white', paddingLeft: 30 }}> Взахлёб admin-panel</span>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 'calc(100vh - 114px)',
              background: '#fff',
            }}
          >
            <Suspense fallback={<Spin className="spin-loader-content" tip="Loading..." size="large" />}>
              <Routes>
                {!logged ? (
                  <>
                    <Route path="/login" element={<LoginComponent isAuthed={loginStatus} />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                  </>
                ) : (
                  <>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/stories" element={<StoriesList />} />
                    <Route path="/edit-story/:id" element={<EditStoryComponent />} />
                    <Route path="/create-story" element={<CreateStoryComponent />} />
                    <Route path="/comments" element={<CommentListComponent />} />
                    <Route path="/edit-comments/:id" element={<EditCommentsComponent />} />
                    <Route path="/characters" element={<CharactersComponent />} />
                    <Route path="/edit-character/:id" element={<EditCharacterComponent />} />
                    <Route path="/banners" element={<BannerListComponent />} />
                    <Route path="/edit-banner/:id" element={<EditBannerComponent />} />
                    <Route path="/create-banner" element={<EditBannerComponent />} />
                    <Route path="/edit-episode/:id" element={<EditEpisodeComponent />} />
                    <Route path="/edit-time-staps/:id" element={<EditTimeStapsComponent />} />
                    <Route path="/planning" element={<PlanningComponent />} />
                    <Route path="/episodes" element={<EpisodesListComponent />} />
                    <Route path="/tools" element={<ToolsComponent />} />
                    <Route path="/catalog" element={<CatalogComponent />} />
                    <Route path="/clones" element={<ClonesComponent />} />
                    <Route path="/pushes" element={<PushComponent />} />
                    <Route path="/tags" element={<TagsComponent />} />
                    <Route path="/fandoms" element={<FandomsComponent />} />
                    <Route path="/subscriptions" element={<SubscriptionsComponent />} />
                    <Route path="/edit-subscription/:id" element={<EditSubscriptionComponent />} />
                    <Route path="/subscription-plans" element={<SubscriptionPlansComponent />} />
                    <Route path="/categories" element={<CategoriesComponent />} />
                    <Route path="/edit-user/:id" element={<EditUserComponent />} />
                    <Route path="/blacklist" element={<BlacklistComponent />} />
                    {role === 'SUPER_ADMIN' && (
                      <>
                        <Route path="/users" element={<UsersListComponent />} />
                        <Route path="/create-user" element={<CreateUserComponent />} />
                      </>
                    )}
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={history.location && history.location.pathname !== '/login' ? history.location : '/'}
                        />
                      }
                    />
                  </>
                )}
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default ApplicationRoutes;
