export const COMMENTS_STATUSES = [
  { label: '🤗 Утвердить', value: 'approved' },
  { label: '😠 Отклонить', value: 'declined' },
  { label: '👻 Удалить', value: 'deleted' },
  { label: '🤔 Новый комментарий', value: 'new' },
];

export const PAGE_DEFAULT = 1;
export const PAGE_LIMIT = 30;

export const SELECT_OPTIONS_CURRENCY = [{ label: '₽ - Российский рубль', value: 'RUB' }];

export const AGE_RATING_OPTIONS = [
  { label: '6+ (для всех)', value: 6 },
  { label: '12+', value: 12 },
  { label: '16+', value: 16 },
  { label: '18+ (только для взрослых)', value: 18 },
];

export const EPISODE_STATUSES = [
  { label: 'Черновик', value: 'draft' },
  { label: 'На проверке', value: 'review' },
  { label: 'Отклонено - много ошибок', value: 'rejected_errors' },
  { label: 'Отклонено - недоработанный текст', value: 'rejected_unique' },
  { label: 'Отклонено модератором', value: 'rejected_moderator' },
  { label: 'Модерация', value: 'moderation' },
  { label: 'Опубликовано', value: 'published' },
];

export const SELECT_OPTIONS_LANG = [
  { label: '🇷🇺 Russian', value: 'ru' },
  { label: '🇬🇧 English', value: 'en' },
  { label: '🇩🇪 Deutsch', value: 'de' },
  { label: '🇵🇹 Portuguese', value: 'pt-br' },
  { label: '🇦🇪 Arabic', value: 'ar' },
];

export const DEVICES_OPTIONS = [
  { label: '🐶 android', value: 'android' },
  { label: '🦊 ios', value: 'ios' },
];

export const BOOLEAN_OPTIONS = [
  { label: 'true', value: true },
  { label: 'false', value: false },
];

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 12 },
  },
};

export const EMPTY_DATES_OPTIONS = [
  { label: 'Релиз заполнен', value: 'releasedAt-' },
  { label: 'Публикация заполнена', value: 'publishedAt-' },
  { label: 'Релиз НЕ заполнен', value: 'releasedAt-null' },
  { label: 'Публикация НЕ заполнена', value: 'publishedAt-null' },
];

export const STORY_PREFIX_API = 'stories';
export const EPISODE_PREFIX_API = 'episodes';

export const PLANNING_OPTIONS = [
  { label: 'Истории', value: STORY_PREFIX_API },
  { label: 'Эпизоды', value: EPISODE_PREFIX_API },
  {
    label: 'Календарь',
    value: 'calendar',
  },
];

export const STORY_CATALOG_SORT_FIELDS = [
  { label: 'Дата релиза', value: 'releasedAt' },
  { label: 'Рейтинг', value: 'rating' },
  { label: 'Читаемость', value: 'readability' },
];

export const STORY_CONTENT_TYPE = [
  { label: 'Audio perfomance', value: 'audioPerformance' },
  { label: 'Quest', value: 'quest' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'AudioStory', value: 'audioStory' },
  { label: 'Chat', value: 'chat' },
  { label: 'Video', value: 'video' },
  { label: 'Prose', value: 'prose' },
  { label: 'Comics', value: 'comics' },
  { label: 'Combo', value: 'combo' },
];

export const USER_ROLES = [
  { label: 'Super admin', value: 1 },
  { label: 'Admin', value: 2 },
  { label: 'Editor', value: 3 },
  { label: 'Analyst', value: 4 },
  { label: 'User', value: 5 },
];

export const EPISODE_CONTENT_LENGTH_LIMITATION = 1000;
export const EPISODE_CONTENT_LENGTH_MAXIMUM = 20000;

export const SUBSCRIPTION_STATUSES = [
  { label: '✅ Активна', value: 'active' },
  { label: '❌ Отменена', value: 'canceled' },
];

export const BLACKLIST_TYPES = [
  { label: '💬 Комментарии', value: 'comments' },
  { label: '📚 Предложка', value: 'stories' },
];
