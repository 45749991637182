import React from 'react';
import ApplicationRoutes from './config/ApplicationRoutes';
import './App.css';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Samara');

function App() {
  return <ApplicationRoutes />;
}

export default App;
