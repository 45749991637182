import { api } from '.';
import { PAGE_DEFAULT, PAGE_LIMIT } from '../utils/const';

export const getFandoms = (page = PAGE_DEFAULT, limit = PAGE_LIMIT, searchCriteria = null, searchParams = null) =>
  api.get(`/fandoms/`, { params: { page, limit, searchCriteria, ...searchParams } });

export const createFandom = (data) => api.post(`/fandoms/`, { ...data });

export const updateFandom = (objectId, data) => api.patch(`/fandoms/${objectId}`, { ...data });

export const deleteFandom = (objectId, data) => api.delete(`/fandoms/${objectId}`, { ...data });

export const deleteFandoms = (data) => api.patch(`/fandoms/`, { ...data });

export const transferFandoms = (groupId, data) => api.patch(`/fandoms/transfer/${groupId}`, { ...data });

export const getFandomModerationNotification = () => api.get(`/fandoms/notification/moderation`);
