import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  LogoutOutlined,
  HighlightOutlined,
  DashboardOutlined,
  TeamOutlined,
  CommentOutlined,
  UsergroupAddOutlined,
  AccountBookOutlined,
  ScheduleOutlined,
  PartitionOutlined,
  ToolOutlined,
  AppstoreOutlined,
  BarcodeOutlined,
  MailOutlined,
  TagsOutlined,
  CreditCardOutlined,
  BorderlessTableOutlined,
  DollarCircleOutlined,
  StopOutlined,
  SubnodeOutlined,
} from '@ant-design/icons';
import { getFandomModerationNotification } from '../api/fandom';
import { getModerationNotification } from '../api/episode';
import { getTagModerationNotification } from '../api/tag';

const SideNav = (props) => {
  const loginState = props.isAuthed;
  const [logged, setLogged] = useState(false);
  const [role, setRole] = useState('');

  const [moderationNotification, setModerationNotification] = useState(0);
  const [customTags, setCustomTags] = useState(0);
  const [customFandoms, setCustomFandoms] = useState(0);

  const fetchModerationNotification = () => {
    getModerationNotification()
      .then((res) => setModerationNotification(Number.isInteger(res.data) ? res.data : 0))
      .finally(() => setTimeout(() => fetchModerationNotification(), 120000));
  };

  const fetcTagModerationNotification = () => {
    getTagModerationNotification()
      .then((res) => setCustomTags(Number.isInteger(res.data) ? res.data : 0))
      .finally(() => setTimeout(() => getTagModerationNotification(), 120000));
  };

  const fetchFandomModerationNotification = () => {
    getFandomModerationNotification()
      .then((res) => setCustomFandoms(Number.isInteger(res.data) ? res.data : 0))
      .finally(() => setTimeout(() => getFandomModerationNotification(), 120000));
  };

  useEffect(() => {
    fetchModerationNotification();
    fetcTagModerationNotification();
    fetchFandomModerationNotification();
  }, []);

  useEffect(() => {
    setLogged(props.logged);
    setRole(props.role);
  }, [props.logged, props.role]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('logged');
    localStorage.removeItem('user');
    loginState();
  };

  return (
    <div>
      {logged && (
        <>
          <div style={{ height: '32px', background: 'rgba(255, 255, 255, 0.2)', margin: '16px' }}></div>
          <Menu
            theme="dark"
            mode="inline"
            items={[
              {
                key: 1,
                icon: <DashboardOutlined />,
                label: <Link to="/">Главная</Link>,
              },
              {
                key: 2,
                icon: <HighlightOutlined />,
                label: <Link to="/stories">Истории</Link>,
              },
              {
                key: 3,
                icon: <PartitionOutlined />,
                label: (
                  <Link to="/episodes">Эпизоды {moderationNotification > 0 && `(${moderationNotification})`}</Link>
                ),
              },
              {
                key: 4,
                icon: <AccountBookOutlined />,
                label: <Link to="/banners">Промо</Link>,
              },
              {
                key: 5,
                icon: <CommentOutlined />,
                label: <Link to="/comments">Комментарии</Link>,
              },
              {
                key: 6,
                icon: <UsergroupAddOutlined />,
                label: <Link to="/characters">Персонажи</Link>,
              },
              {
                key: 16,
                icon: <TagsOutlined />,
                label: <Link to="/categories">Категории</Link>,
              },
              {
                key: 14,
                icon: <BorderlessTableOutlined />,
                label: <Link to="/tags">Теги {customTags > 0 && `(${customTags})`}</Link>,
              },
              {
                key: 19,
                icon: <SubnodeOutlined />,
                label: <Link to="/fandoms">Фандомы {customFandoms > 0 && `(${customFandoms})`}</Link>,
              },
              {
                key: 17,
                icon: <CreditCardOutlined />,
                label: <Link to="/subscriptions">Подписки</Link>,
              },
              {
                key: 15,
                icon: <DollarCircleOutlined />,
                label: <Link to="/subscription-plans">Планы подписки</Link>,
              },
              {
                key: 18,
                icon: <StopOutlined />,
                label: <Link to="/blacklist">Блэклист</Link>,
              },
              {
                key: 7,
                icon: <ScheduleOutlined />,
                label: <Link to="/planning">Планирование</Link>,
              },
              {
                key: 8,
                icon: <AppstoreOutlined />,
                label: <Link to="/catalog">Каталог</Link>,
              },
              {
                key: 9,
                icon: <BarcodeOutlined />,
                label: <Link to="/clones">Клоны</Link>,
              },
              {
                key: 13,
                icon: <MailOutlined />,
                label: <Link to="/pushes">Автопуши</Link>,
              },
              {
                key: 10,
                icon: <ToolOutlined />,
                label: <Link to="/tools">Инструменты</Link>,
              },
              logged &&
                role === 'SUPER_ADMIN' && {
                  key: 11,
                  icon: <TeamOutlined />,
                  label: <Link to="/users">Пользователи</Link>,
                },
              {
                key: 12,
                icon: <LogoutOutlined />,
                label: 'Выйти',
                onClick: handleLogout,
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default SideNav;
