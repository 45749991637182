const isLogged = () => {
    const logged = localStorage.getItem('logged');
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    return {
        logged,
        user,
        token
    }
}
export default isLogged;